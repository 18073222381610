// contains default image paths
export const MobPath = "mob.png";
export const SmilePath = "smile.png";
export const IconMoneyBackPath = "icon-moneyback.png";
export const CarIconPath = "car-icon.png";
export const CarDeskPath = "car-desk.png";
export const Car1Path = "car1.png";
export const ThisBgPath = "this_bg.png";
export const PromisePath = "promise.png";
export const BannerPath = "banner.png";
export const FastTrackPath = "fast_track.png";
export const MoreCarsPath = "more_cars.png";
export const PerfectCarPath = "perfect_car.png";
export const ImgRight2Path = "img_right_2.png";
export const ImgLeft3Path = "img_left_3.png";
export const ImgRight1Path = "img_right_1.png";
export const WorkCarPath = "work-car.png";
export const FooterNewBgPath = "footer_new_bg.png";
export const LogoPath = "logo.png";
export const FacebookPath = "Facebook.svg";
export const InstaPath = "Insta.svg";
export const RelaxImagePath = "relax.png";
export const SellTradeImagePath = "sell-trade.png";
export const CareersImage = "career-img-banner.png";
export const DefaultImage404 = "404Image.gif";
export const ChatImagePath = "chat.png";
export const SellOrTradeUpdatedPath = "sell-trade-updated-file.jpg";
